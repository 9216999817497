<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="topografia"
    :hasExam="hasAttendanceValues('exam')"
    @getData="importAttendanceData(null, $event)"
  >
    <b-row>
      <b-col cols="12" class="mb-2">
        <toggle-button
          class="mostrarSimplicadaBtn"
          v-model="form.fields.mostrarSimplificada"
          @change="handleToggle('mostrarSimplificada')"
          :sync="true"
          :height="24"
          :disabled="!canEdit"
          :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
        />
        <span class="autoFillBtnLabel">Visualização padrão simplificada</span>
      </b-col>
      <b-col>
        <b-col>
          <label for="aparelho">Aparelho</label>
          <multiselect
            :value="aparelhoSelected"
            id="aparelho"
            label="name"
            :options="opcoesAparelho"
            :option-height="40"
            :showLabels="false"
            :showNoResults="true"
            :disabled="!canEdit"
            @select="value => onSelect('aparelho', value)"
            placeholder="Selecione o tipo do aparelho"
            class="with-border"
          >
            <template slot="noResult" slot-scope="props">
              <li @click="setAparelho(props.search)">
                <div class="multiselect__option custom-item">
                  <Plus class="icon" />
                  <p class="blue">Adicionar aparelho "{{ props.search }}"</p>
                </div>
              </li>
            </template>
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
              <div class="new-topografia">
                <Icon tooltip="Limpa seleção">
                  <Close class="icon close" @click="clearFields" />
                </Icon>
                <Icon tooltip="Editar" v-if="showAddCustom && canEdit">
                  <Edit class="icon edit" @click="editTopografia()" />
                </Icon>
                <Icon tooltip="Deletar" v-if="showAddCustom && canEdit">
                  <Trash
                    class="icon trash"
                    @click="deleteTopografia(aparelhoSelected?.id)"
                  />
                </Icon>
              </div>
            </template>
          </multiselect>
        </b-col>
        <b-row>
          <b-col cols="6">
            <div class="custom-input-group">
              <div class="custom-input h-38">
                <div class="eye-area h-38">
                  <EyeFill />
                  D
                </div>
                <div class="custom-input eye-box-container h-38 no-bb with-br">
                  <span>Curvatura</span>
                </div>
                <div class="custom-input eye-box-container h-38 no-bb with-br">
                  <span>Raio</span>
                </div>
                <div
                  class="custom-input eye-box-container eye-box-container-corner h-38 no-bb"
                >
                  <span>Eixo</span>
                </div>
              </div>
              <div class="custom-input no-bb">
                <div class="eye-area no-br">K1</div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoK1Curvatura"
                    :readonly="!canEdit"
                    @input="
                      el =>
                        updateForm('olhoDireitoK1Curvatura', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-direito"
                    type="text"
                    class="form-control"
                    ref="olhoDireitoK1Curvatura"
                  />
                </div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoK1Raio"
                    :readonly="!canEdit"
                    @input="
                      el => updateForm('olhoDireitoK1Raio', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-direito"
                    type="text"
                    class="form-control"
                    ref="olhoDireitoK1Raio"
                  />
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoK1Eixo"
                    :readonly="!canEdit"
                    @input="
                      el => updateForm('olhoDireitoK1Eixo', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoDireitoK1Eixo"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="custom-input no-bb">
                <div class="eye-area no-br">K2</div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoK2Curvatura"
                    :readonly="!canEdit"
                    @input="
                      el =>
                        updateForm('olhoDireitoK2Curvatura', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoDireitoK2Curvatura"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoK2Raio"
                    :readonly="!canEdit"
                    @input="
                      el => updateForm('olhoDireitoK2Raio', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoDireitoK2Raio"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoK2Eixo"
                    :readonly="!canEdit"
                    @input="
                      el => updateForm('olhoDireitoK2Eixo', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoDireitoK2Eixo"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="custom-input no-bb">
                <div class="eye-area no-br">Kmax (ápice)</div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoKmaxCurvatura"
                    :readonly="!canEdit"
                    @input="
                      el =>
                        updateForm('olhoDireitoKmaxCurvatura', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoDireitoKmaxCurvatura"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoKmaxRaio"
                    :readonly="!canEdit"
                    @input="
                      el => updateForm('olhoDireitoKmaxRaio', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoDireitoKmaxRaio"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoKmaxEixo"
                    :readonly="!canEdit"
                    @input="
                      el => updateForm('olhoDireitoKmaxEixo', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoDireitoKmaxEixo"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <template v-if="!form.fields.mostrarSimplificada">
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">Kmédio</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoDireitoKmedioCurvatura"
                      :readonly="!canEdit"
                      @input="
                        el => updateForm('olhoDireitoKmedioCurvatura', el.target.value)
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoDireitoKmedioCurvatura"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
              </template>
              <div class="custom-input no-bb">
                <div class="eye-area no-br">Cilindro</div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoCilindro"
                    :readonly="!canEdit"
                    @input="
                      el => updateForm('olhoDireitoCilindro', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoDireitoCilindro"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <template v-if="!form.fields.mostrarSimplificada">
                <div class="custom-input h-38">
                  <div
                    class="custom-input eye-box-container h-38 no-bb "
                  >
                    <span>Resultado</span>
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">Esp. C. Córn.</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoDireitoEspessuraCentralCornea"
                      :readonly="!canEdit"
                      @input="
                        el =>
                          updateForm(
                            'olhoDireitoEspessuraCentralCornea',
                            el.target.value
                          )
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoDireitoEspessuraCentralCornea"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">Diâm. Pupil.</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoDireitoDiametroPupila"
                      :readonly="!canEdit"
                      @input="
                        el =>
                          updateForm(
                            'olhoDireitoDiametroPupila',
                            el.target.value
                          )
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoDireitoDiametroPupila"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">Diâm. Corne.</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoDireitoDiametroCornea"
                      :readonly="!canEdit"
                      @input="
                        el =>
                          updateForm(
                            'olhoDireitoDiametroCornea',
                            el.target.value
                          )
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoDireitoDiametroCornea"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">Âng. kappa</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoDireitoAnguloKappa"
                      :readonly="!canEdit"
                      @input="
                        el =>
                          updateForm('olhoDireitoAnguloKappa', el.target.value)
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoDireitoAnguloKappa"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">Âng. nasal</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoDireitoAnguloNasal"
                      :readonly="!canEdit"
                      @input="
                        el =>
                          updateForm('olhoDireitoAnguloNasal', el.target.value)
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoDireitoAnguloNasal"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">Âng. temp.</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoDireitoAnguloTemporal"
                      :readonly="!canEdit"
                      @input="
                        el =>
                          updateForm(
                            'olhoDireitoAnguloTemporal',
                            el.target.value
                          )
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoDireitoAnguloTemporal"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">KPI</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoDireitoKpi"
                      :readonly="!canEdit"
                      @input="
                        el => updateForm('olhoDireitoKpi', el.target.value)
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoDireitoKpi"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
              </template>
              <div class="custom-input no-bb">
                <div class="eye-area">Impressão</div>
                <div class="custom-input no-bb bbr">
                  <TextArea
                    id="DireitoImpressao"
                    :value="
                      form.fields.olhoDireito
                        ? form.fields.olhoDireito
                        : form.fields.DireitoImpressao
                    "
                    @blur="updateMedicalRecord(form)"
                    @inputTextArea="el => updateForm('DireitoImpressao', el)"
                    :readonly="!canEdit"
                    rows="1"
                    class="form-control border-text"
                  />
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="custom-input-group">
              <div class="custom-input h-38">
                <div class="eye-area h-38">
                  <EyeFill />
                  E
                </div>
                <div class="custom-input eye-box-container h-38 no-bb with-br">
                  <span>Curvatura</span>
                </div>
                <div class="custom-input eye-box-container h-38 no-bb with-br">
                  <span>Raio</span>
                </div>
                <div
                  class="custom-input eye-box-container eye-box-container-corner h-38 no-bb"
                >
                  <span>Eixo</span>
                </div>
              </div>
              <div class="custom-input no-bb">
                <div class="eye-area no-br">K1</div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoK1Curvatura"
                    :readonly="!canEdit"
                    @input="
                      el =>
                        updateForm('olhoEsquerdoK1Curvatura', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-esquerdo"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoK1Raio"
                    :readonly="!canEdit"
                    @input="
                      el => updateForm('olhoEsquerdoK1Raio', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoEsquerdoK1Raio"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoK1Eixo"
                    :readonly="!canEdit"
                    @input="
                      el => updateForm('olhoEsquerdoK1Eixo', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoEsquerdoK1Eixo"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="custom-input no-bb">
                <div class="eye-area no-br">K2</div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoK2Curvatura"
                    :readonly="!canEdit"
                    @input="
                      el =>
                        updateForm('olhoEsquerdoK2Curvatura', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoEsquerdoK2Curvatura"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoK2Raio"
                    :readonly="!canEdit"
                    @input="
                      el => updateForm('olhoEsquerdoK2Raio', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoEsquerdoK2Raio"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoK2Eixo"
                    :readonly="!canEdit"
                    @input="
                      el => updateForm('olhoEsquerdoK2Eixo', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoEsquerdoK2Eixo"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="custom-input no-bb">
                <div class="eye-area no-br">Kmax (ápice)</div>

                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoKmaxCurvatura"
                    :readonly="!canEdit"
                    @input="
                      el =>
                        updateForm('olhoEsquerdoKmaxCurvatura', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoEsquerdoKmaxCurvatura"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoKmaxRaio"
                    :readonly="!canEdit"
                    @input="
                      el => updateForm('olhoEsquerdoKmaxRaio', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoEsquerdoKmaxRaio"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoKmaxEixo"
                    :readonly="!canEdit"
                    @input="
                      el => updateForm('olhoEsquerdoKmaxEixo', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoEsquerdoKmaxEixo"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <template v-if="!form.fields.mostrarSimplificada">
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">Kmédio</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoEsquerdoKmedioCurvatura"
                      :readonly="!canEdit"
                      @input="
                        el => updateForm('olhoEsquerdoKmedioCurvatura', el.target.value)
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoEsquerdoKmedioCurvatura"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
              </template>
              <div class="custom-input no-bb">
                <div class="eye-area no-br">Cilindro</div>

                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoCilindro"
                    :readonly="!canEdit"
                    @input="
                      el => updateForm('olhoEsquerdoCilindro', el.target.value)
                    "
                    @blur="updateMedicalRecord(form)"
                    id="topografia-olhoEsquerdoCilindro"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <template v-if="!form.fields.mostrarSimplificada">
                <div class="custom-input h-38">
                  <div
                    class="custom-input eye-box-container h-38 no-bb"
                  >
                    <span>Resultado</span>
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">Esp. C. Córn.</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoEsquerdoEspessuraCentralCornea"
                      :readonly="!canEdit"
                      @input="
                        el =>
                          updateForm(
                            'olhoEsquerdoEspessuraCentralCornea',
                            el.target.value
                          )
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoEsquerdoEspessuraCentralCornea"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">Diam. Pupil.</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoEsquerdoDiametroPupila"
                      :readonly="!canEdit"
                      @input="
                        el =>
                          updateForm(
                            'olhoEsquerdoDiametroPupila',
                            el.target.value
                          )
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoEsquerdoDiametroPupila"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">Diam. Córne.</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoEsquerdoDiametroCornea"
                      :readonly="!canEdit"
                      @input="
                        el =>
                          updateForm(
                            'olhoEsquerdoDiametroCornea',
                            el.target.value
                          )
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoEsquerdoDiametroCornea"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">Âng. kappa</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoEsquerdoAnguloKappa"
                      :readonly="!canEdit"
                      @input="
                        el =>
                          updateForm('olhoEsquerdoAnguloKappa', el.target.value)
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoEsquerdoAnguloKappa"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">Âng. nasal</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoEsquerdoAnguloNasal"
                      :readonly="!canEdit"
                      @input="
                        el =>
                          updateForm('olhoEsquerdoAnguloNasal', el.target.value)
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoEsquerdoAnguloNasal"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">Âng. temp.</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoEsquerdoAnguloTemporal"
                      :readonly="!canEdit"
                      @input="
                        el =>
                          updateForm(
                            'olhoEsquerdoAnguloTemporal',
                            el.target.value
                          )
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoEsquerdoAnguloTemporal"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area no-br">KPI</div>
                  <div class="custom-input no-bb">
                    <input
                      autocomplete="off"
                      :value="form.fields.olhoEsquerdoKpi"
                      :readonly="!canEdit"
                      @input="
                        el => updateForm('olhoEsquerdoKpi', el.target.value)
                      "
                      @blur="updateMedicalRecord(form)"
                      id="topografia-olhoEsquerdoKpi"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
              </template>
              <div class="custom-input no-bb">
                <div class="eye-area">Impressão</div>
                <div class="custom-input no-bb bbr">
                  <TextArea
                    id="EsquerdoImpressao"
                    :value="
                      form.fields.olhoEsquerdo
                        ? form.fields.olhoEsquerdo
                        : form.fields.EsquerdoImpressao
                    "
                    @blur="updateMedicalRecord(form)"
                    @inputTextArea="el => updateForm('EsquerdoImpressao', el)"
                    :readonly="!canEdit"
                    rows="1"
                    class="form-control border-text"
                  />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <TopografiaModal :data="aparelhoSelected" @setTopografia="setTopografia">
    </TopografiaModal>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    TextArea: () => import('@/components/General/TextArea'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Plus: () => import('@/assets/icons/plus.svg'),
    Icon: () => import('@/components/General/Icon'),
    Trash: () => import('@/assets/icons/trash.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    TopografiaModal: () =>
      import('@/components/Attendance/Forms/Components/TopografiaModal')
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.topografia,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore
    }),
    ...mapGetters('attendance', ['exam']),
    showAddCustom() {
      return this.user.id === this.aparelhoSelected?.professional_id
    },
    aparelhoSelected() {
      const aparelho = this.opcoesAparelho.find(
        aparelho => aparelho.name === this.form.fields.aparelho
      )
      if (!aparelho) {
        return null
      }
      return aparelho
    }
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  async mounted() {
    await this.getTopografiaOptions()
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      opcoesAparelho: []
    }
  },
  methods: {
    ...mapActions('attendance/form', [
      'updateMedicalRecord',
      'destroyMedicalRecord'
    ]),
    ...mapActions('attendance/form/topografia', [
      'handleFields',
      'handleProps'
    ]),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    hideForm() {
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      })
    },
    onSelect(key, value) {
      this.updateForm(key, value.name)
      this.updateMedicalRecord(this.form)
    },
    setAparelho(value) {
      if (!value.length) return
      this.opcoesAparelho.push(value)
      this.updateForm('aparelho', value)
      this.updateMedicalRecord(this.form)
      this.saveTopografia(value)
    },
    async saveTopografia(value) {
      try {
        const response = await this.api.storeTopografia({
          professional_id: this.user.id,
          name: value
        })
        await this.getTopografiaOptions()
        this.$toast.success('Aparelho para Topografia adcionado com sucesso!')
        return response
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getTopografiaOptions() {
      try {
        const response = await this.api.getTopografiaOptions(this.user.id)
        this.opcoesAparelho = response.data
        this.opcoesAparelho = response.data.map(note => {
          return {
            id: note.id,
            professional_id: note.professional_id,
            name: note.name
          }
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    editTopografia() {
      this.$refs.olhoDireitoK1Curvatura.focus()
      this.$bvModal.show('open-topografia-modal')
    },
    async deleteTopografia(value) {
      try {
        await this.api.deleteTopografia(value)
        await this.getTopografiaOptions()
        await this.clearFields()
        await this.updateForm()
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    clearFields() {
      this.form.fields.aparelho = null
      this.updateForm()
      this.updateMedicalRecord(this.form)
    },
    handleToggle(key) {
      this.updateForm(key, this.form.fields[key])
      this.updateMedicalRecord(this.form)
    },
    setTopografia(topografia) {
      const index = this.opcoesAparelho.findIndex(
        value => value.id === topografia.id
      )
      this.opcoesAparelho.splice(index, 1, topografia)
      this.onSelect('aparelho', topografia)
    },
    async importAttendanceData(medicalRecords = null, type) {
      if (!this.hasAttendanceValues(type)) return
      await this.simpleImportData(medicalRecords, type)
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-input-group {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-300);
  border-radius: 8px;
  margin: 24px 0 16px 0;

  .custom-input {
    flex: 1;
    border: 8px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--neutral-300);
    justify-content: center;

    &.h-38 {
      height: 38px;
    }

    .form-control {
      border-radius: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
      border-top: 1 solid var(--neutral-300) !important;
    }

    .border-text {
      border-bottom: 0px !important;
      border-radius: 0px 0px 10px 0px !important;
    }

    .eye-area {
      width: 140px;
      background-color: var(--neutral-100);
      border-right: 1px solid var(--neutral-300);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px 0 0 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      &.no-br {
        border-radius: 0 !important;
        border-bottom: 1px solid var(--neutral-300);
      }

      svg {
        width: 16px;
        height: 16px;
        fill: var(--type-active);
        margin-right: 4px;
      }
    }

    &.no-bb {
      border-bottom: 0;

      .eye-area {
        border-radius: 0 0 0 8px;
      }
    }

    &.no-br {
      border-right: 0;
    }

    &.with-br {
      border-right: 1px solid var(--neutral-300);
    }

    &.bbr {
      border-radius: 0 0 8px 0 !important;
    }

    .text-area {
      flex: 1;
      line-height: 55px;
      margin-left: 16px;
      font-weight: 400;
      font-size: 16px;
      color: var(--type-active);

      .form-control {
        border: 0 !important;
        border-radius: 0 !important;

        &.with-bbr {
          border-radius: 0 0 8px 0 !important;
        }

        &.with-btr {
          border-radius: 0 8px 0 0 !important;
        }
      }

      &.type-2 {
        position: relative;
        margin-left: 0;
        text-align: center;

        span {
          width: 100%;
          position: absolute;
          top: -19px;
          left: 0;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: var(--type-active);
        }
      }

      &.with-br {
        border-right: 1px solid var(--neutral-300) !important;
      }

      &.with-brtr {
        border-radius: 0 8px 0 0 !important;
      }

      &.with-brbr {
        border-radius: 0 0 8px 0 !important;
      }
    }
  }
}

.eye-box-container {
  background-color: var(--neutral-100);
  align-items: center;

  .eyeValueChange {
    visibility: hidden;
  }

  &:hover {
    .eyeValueChange {
      visibility: visible;
    }
  }
}

.eye-box-container-corner {
  border-radius: 0 8px 0 0;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  fill: var(--blue-500);
}

.new-topografia {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  top: 0;
  right: 10px;
  z-index: 10;
  padding-right: 30px;

  .icon {
    width: 20px;
    height: 20px;
    fill: var(--blue-500);
    cursor: pointer;

    &.trash {
      fill: none;
      stroke: var(--states-red-soft);
    }

    &.edit {
      fill: none;
      stroke: var(--blue-500);
    }

    &.close {
      fill: var(--type-active);
    }
  }
}

.blue {
  color: var(--blue-500);
  display: inline-block;
}
</style>
